html, #root {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

footer {
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

header {
  height: 90px;
  background-color: #3f51b5;
}

input {
  font-family: inherit;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: larger;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
  height: 40px;
  width: 45%;
  color: white;
  margin-bottom: 15px;
}

img {
  height: 30px;
  width: 30px;
  padding: 10px;;
}

button {
  margin-left: 20px;
  font-size: larger;
  width: 13%;
  min-width: 100px;
  color: #9b9b9b;
  height: 50px;
  border: solid rgba(255,255,255,0.3) 1px;
  background: transparent;
  cursor:pointer;
}

button:disabled{
  cursor: not-allowed;
}

select {
  font-size: larger;
  width: 13%;
  margin: 0 10px;
  min-width: 100px;
  color: #9b9b9b;
  height: 50px;
  border: solid rgba(255,255,255,0.3) 1px;
  background: transparent;
  cursor:pointer;
}