.App {
  text-align: center;
  background-color: #2c2f37;
  height: 100%;
  color: white;
}

#search {
  margin-top: 50vh;
  margin-top: 10em;
}

.result {
  margin-top: 10em;
  display: flex;
  justify-content: center;
}

.category {
  display: flex;
  width: 45%;
  height: 5vh;
  background-color: #3f51b5;
  padding: 20px 0px;
  justify-content: space-evenly;
  align-items: center;
}